<template>
  <validation-observer ref="simpleRules">
    <item-form />
    <save-button :save-action="submitForm" />
  </validation-observer>
</template>

<script>
import { ValidationObserver, localize } from 'vee-validate'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import ItemForm from '@/views/Admin/Welcome/ItemForm.vue'
import SaveButton from '@/layouts/components/common/SaveButton.vue'

export default {
  name: 'Index',
  components: {
    ValidationObserver,
    SaveButton,
    ItemForm,
  },
  data() {
    return {
      required,
      imageUrl: this.$store.state.app.baseURL,
      itemsForm: false,
      selectItems: null,

    }
  },
  computed: {
    itemData() {
      return this.$store.getters['welcome/dataItem']
    },
    saveData() {
      return this.$store.getters['welcome/dataSaveStatus']
    },
  },
  watch: {
    saveData(val) {
      if (val.status === true) {
        this.showToast({
          variant: 'success', title: 'İşlem Başarılı!', icon: 'CheckIcon', text: val.message,
        })
        this.getData()
      } else if (val.status === false) {
        this.showToast({
          variant: 'danger', title: 'İşlem Hatası!', icon: 'XIcon', text: val.message,
        })
      }
    },
  },
  created() {
    localize('tr')
    this.getData()
  },
  methods: {
    getData() {
      this.$store.dispatch('welcome/getDataItem')
    },
    submitForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.$store.dispatch('welcome/saveData', this.itemData)
        } else {
          this.showToast({
            title: 'Uyarı!',
            icon: 'XIcon',
            text: 'Lütfen tüm alanları doldurun.',
            variant: 'danger',
          })
        }
      })
    },
    showToast(params) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: params.title,
          icon: params.icon,
          text: params.text,
          variant: params.variant,
        },
      }, {
        position: 'bottom-center',
      })
    },
  },
}
</script>
