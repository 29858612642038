<template>
  <b-form-group
    label="Metin"
    label-for="content"
  >
    <b-form-textarea
      id="content"
      v-model="itemData.content"
      placeholder="Metin"
    />
  </b-form-group>
</template>

<script>
import { BFormGroup, BFormTextarea } from 'bootstrap-vue'

export default {
  name: 'ContentText',
  components: {
    BFormGroup,
    BFormTextarea,
  },
  computed: {
    itemData() {
      return this.$store.getters['welcome/dataItem']
    },
  },
}
</script>
