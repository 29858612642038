<template>
  <b-card>
    <title-text />
    <content-text />
  </b-card>
</template>
<script>
import TitleText from '@/views/Admin/Welcome/elements/TitleText.vue'
import ContentText from '@/views/Admin/Welcome/elements/ContentText.vue'
import { BCard } from 'bootstrap-vue'

export default {
  name: 'ItemForm',
  components: {
    BCard,
    ContentText,
    TitleText,
  },
}
</script>
